<template>
  <div class="about">
    <h1>About Launchpad</h1>

    <h2>What Is Launchpad?</h2>

    <p>Launchpad lets you launch your games from any platform in a
      universally-compatible way that includes Steam overlay support.</p>

    <p>It was born out of frustration with trying to manage non-Steam games
    effectively, but it has since outgrown its humble origins and turned into a
    full-fledged game launching multi-tool.</p>

    <p>Launchpad was conceived and is under active development by Ben McClure of Volantis
      Development. This is done entirely for free, but if you desire to donate to Ben to go toward
      his costs of cloud infrastructure and continued development of Launchpad, there are a few ways
      to do so:</p>

    <div class="bmac-button"></div>

    <iframe src="https://ghbtns.com/github-btn.html?user=bmcclure&type=sponsor" frameborder="0" scrolling="0" width="140" height="20" title="GitHub"></iframe>

    <form action="https://www.paypal.com/donate" method="post" target="_top">
      <input type="hidden" name="hosted_button_id" value="QVQLR4HJWU99E" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form>

    <h2>Gallery</h2>

    <div class="screenshot">
        <img src="https://firebasestorage.googleapis.com/v0/b/launchpad-306703.appspot.com/o/main_window.png?alt=media&token=ec2cfd39-3a52-444e-b45a-c8567bf439d9" alt="Launchpad Main Window">
    </div>

    <p>There will be a gallery here soon full of screenshots and videos
      of Launchpad in use! Stay tuned.</p>
  </div>
</template>

<style lang="stylus">

</style>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class About extends Vue {
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js');
    script.setAttribute('data-name', 'bmc-button');
    script.setAttribute('data-slug', 'benmcclure');
    script.setAttribute('data-color', '#BD5FFF');
    script.setAttribute('data-emoji', '');
    script.setAttribute('data-font', 'Cookie');
    script.setAttribute('data-text', 'Buy me a coffee');
    script.setAttribute('data-outline-color', '#000000');
    script.setAttribute('data-font-color', '#ffffff');
    script.setAttribute('data-coffee-color', '#FFDD00');

    // todo: Fix this
    document.getElementsByClassName('bmac-button')[0].appendChild(script);
  }
}
</script>
