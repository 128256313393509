
import { Vue } from 'vue-class-component';

export default class About extends Vue {
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js');
    script.setAttribute('data-name', 'bmc-button');
    script.setAttribute('data-slug', 'benmcclure');
    script.setAttribute('data-color', '#BD5FFF');
    script.setAttribute('data-emoji', '');
    script.setAttribute('data-font', 'Cookie');
    script.setAttribute('data-text', 'Buy me a coffee');
    script.setAttribute('data-outline-color', '#000000');
    script.setAttribute('data-font-color', '#ffffff');
    script.setAttribute('data-coffee-color', '#FFDD00');

    // todo: Fix this
    document.getElementsByClassName('bmac-button')[0].appendChild(script);
  }
}
